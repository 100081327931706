import * as React from "react";
import styled from "styled-components";
import { OfferHeader } from "./OfferHeader";
import { OfferFooter } from "./OfferFooter";
import bgDesktop from "../../images/anniversaryOffer/anniversary-background.png";
import goldImg from "../../images/AnniversaryOffer/20gold.png";
// import bgMobile from "../../images/SpecialOffer/backgrounds/bg-mobile-daily.png";
// import bgNarrow from "../../images/SpecialOffer/backgrounds/bg-narrow-daily.png";
import {
  Lerp,
  S3BucketPrefix,
  ScreenSizes,
  screenWidth,
  useScreenSize,
} from "../../shared-code";
import { ExpirationTypes } from "./ExpirationBadge";
import DailyOfferFooter from "./DailyOfferFooter";
import { DailyOrSpecialOffer, award } from "../DataModels";

const OuterWrapper = styled.div`
  position: relative;
  max-width: 685px;
  height: 315px;
`;

const Wrapper = styled.div<{ consumed: boolean }>`
  position: relative;
  width: 100%;
  max-width: 685px;
  height: 315px;
  background-position: right;
  background-size: cover;
  border-radius: 8px;
  cursor: ${(props) => (props.consumed ? "arrow" : "pointer")};
  justify-self: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 0 10px 5px #f45e0d;
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 235px;
  gap: 20px;
  width: 100%;
  z-index: 1;

  @media (max-width: ${ScreenSizes.narrow}) {
    height: 165px;
    margin-top: 30px;
  }
`;

const OfferImage = styled.img<{ consumed: boolean }>`
  height: 240px;
  opacity: ${(props) => (props.consumed ? 0.5 : 1)};

  @media (max-width: ${ScreenSizes.narrow}) {
    height: 155px;
  }
`;

const Awards = styled.div`
  position: absolute;
  right: 10px;
  top: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  z-index: 1;

  @media (max-width: ${ScreenSizes.narrow}) {
    top: 38px;
  }
`;

const AwardName = styled.span`
  color: #fff;
  text-align: right;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  z-index: 1;

  @media (max-width: ${ScreenSizes.tablet}) {
    font-size: 20px;
  }

  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 24px;
  }

  @media (max-width: ${ScreenSizes.narrow}) {
    font-size: 20px;
  }
`;

const AwardAmount = styled.span`
  color: #fff;
  text-align: right;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media (max-width: ${ScreenSizes.tablet}) {
    font-size: 28px;
  }

  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 32px;
  }

  @media (max-width: ${ScreenSizes.narrow}) {
    font-size: 28px;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: -25%;
  height: 315px;
  width: 150%;
  background-repeat: no-repeat;
`;

export interface DailyOfferCardProps {
  offer: DailyOrSpecialOffer;
  purchaseFn: () => void;
}

export default (props: DailyOfferCardProps) => {
  if (props.offer.expires != undefined && props.offer.expires <= 0) {
    return null;
  }
  const localizedAmount = Intl.NumberFormat("en-US").format(
    props.offer.awards[0]?.amount || 0
  );
  const offerDay = props.offer.uniqueId.slice(18, 19);

  return (
    <OuterWrapper>
      <OfferHeader
        loyaltyPoints={props.offer.loyaltyPoints}
        expiration={props.offer.expires}
        serverOffset={props.offer.serverOffset}
        expirationType={ExpirationTypes.refresh}
        stickerText={props.offer.stickerText}
      />
      <Wrapper
        onClick={props.purchaseFn}
        consumed={props.offer.consumed || false}
      >
        <Awards>
          <AwardName>{props.offer.awards[0].text}</AwardName>
          <AwardAmount>{localizedAmount}</AwardAmount>
        </Awards>
        <CardBody>
          <OfferImage
            src={goldImg}
            consumed={props.offer.consumed || false}
            alt=""
          />
        </CardBody>
        <BackgroundImage src={bgDesktop} />
        <DailyOfferFooter
          {...props.offer.price}
          consumed={props.offer.consumed || false}
          titleText={`March Madness Offer!`.toLocaleUpperCase()}
          subTitleText={props.offer.name}
        />
      </Wrapper>
    </OuterWrapper>
  );
};
